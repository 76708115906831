import { useState } from 'react';
import SelectResponseEmailModal from './select-response-email-modal';


const bodyModalClass = ' modal-open';

export const SelectResponseEmail = ({value, onChange, selectedLocationIndex})=>{
    const [showResponseEmailModal, setShowResponseEmailModal] = useState(false);

    const showModal = ()=>{
        const body = document.getElementsByTagName('body')[0];
        body.className = `body.className ${bodyModalClass}`;
        setShowResponseEmailModal(true);
    }
    const hideModal = ()=>{
        const body = document.getElementsByTagName('body')[0];
        body.className = body.className.replace(bodyModalClass, '').trim();
        setShowResponseEmailModal(false);
    }

    const handleSelect = (email) => (
        () => {
            onChange(email);
            hideModal();
        }    
    );

    return (
        <>
            <button type="button" className='open-select-modal' onClick={showModal}>{ !value ? "Select a response email" : value }</button>
            {
                showResponseEmailModal ? 
                    <SelectResponseEmailModal 
                        selectedEmail={value} 
                        closeModal={hideModal} 
                        selectedLocationIndex={selectedLocationIndex} 
                        handleSelect={handleSelect} 
                        handleChange={onChange}
                    /> : 
                    null
            }
        </>

    )
}
