import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import locationData from "./location-data";

const SelectLocationModal = ({closeModal, selectedIndex, handleChange})=>{
    return (
        <div id="select-location-modal" className={'modal modal-outer-wrap '}>
            <div className='modal-content-wrap'>
                <div className="modal-content">
                 
                        <div className="location-option-wrap close-modal-button-wrap">
                            <button type="button" className="location-option-text" onClick={handleChange(selectedIndex)}>
                                <FontAwesomeIcon icon={faArrowLeft} size='sm' />
                                <span>BACK</span>
                            </button>
                        </div>

                    {locationData.map((location, i)=>{
                        const selectLocation = handleChange(i);
                        const selectedClass = selectedIndex === i ? "selected" : '';

                        return (
                            <div  key={`location-option-${i}`}  className={`location-option-wrap ${selectedClass}`}>
                                <button type="button" className="location-option-text" onClick={selectLocation}>{location.name}</button>
                            </div>
                        )
                    })}
                    
                </div>      

                <button className="digitizer" onClick={closeModal}></button>  
            </div>

            
        </div>
    )
}


export default SelectLocationModal;