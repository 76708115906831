import locationData from "./select-location/location-data";

export const generateFilename = (locationId, titleString, dateString) => {
    let locationName = '';
    if(locationId || locationId === 0) {
        if(locationData[locationId].stringId) locationName = locationData[locationId].stringId;
        else if(locationData[locationId].name) locationName = locationData[locationId].name;
    }

    return `RFP${locationName ? `_${locationName}` : '_NO-LOCATION'}${dateString ? `_${dateString}` : ''}${titleString ? `_${titleString}` : ''}`.trim().replace(/ /gi, '-').replace(/[/\\?%*:|"<>]/, '-');
};