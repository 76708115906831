const InputGroup = ({title, value, onChange, placeholder=''})=>{


    return (
        <label className="input-group">
            <p>{title}</p>
            <input type="text" name={title} id={title} value={value} onChange={onChange} placeholder={placeholder}/>
        </label>
    )
}


export default InputGroup;