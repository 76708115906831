import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import locationData from "../select-location/location-data";


const defaultEmailOptions = [
    'CAlexander@MonarchManage.com',
    'RHunt@MonarchManage.com'
];

const SelectResponseEmailModal = ({closeModal, selectedLocationIndex, selectedEmail = '', handleChange, handleSelect})=>{
    let locationOptions = [];
    
    if(selectedLocationIndex || selectedLocationIndex === 0) {
        
        if(locationData[selectedLocationIndex].locationEmails) {
            const locationEmails = locationData[selectedLocationIndex].locationEmails;
            console.log("Emails were thise: ", typeof locationData[selectedLocationIndex].locationEmails);

            if(typeof locationEmails === 'string') {
                locationOptions = [locationEmails];
            }
            if(Array.isArray( locationEmails )) {
                locationOptions = locationEmails;
            }
        }
    }

    const emailOptions = [...defaultEmailOptions, ...locationOptions];

    const currentResponseEmail = selectedEmail;
    const handleEmailEdit = (e)=>{

        handleChange(e.target.value);
    }
 
    return (
        <div id="select-response-email-modal" className={'modal modal-outer-wrap '}>
            
                <div className="modal-content">

                    <div className="location-option-wrap close-modal-button-wrap">
                        <button type="button" className="location-option-text" onClick={handleSelect(selectedEmail)}>
                            <FontAwesomeIcon icon={faArrowLeft} size='sm' />
                            <span>BACK</span>
                        </button>
                    </div>

                    <div className="edit-response-email-wrap">
                        <input 
                            className="location-option-wrap response-email-input" 
                            type="text" 
                            name="response-email-input" 
                            id="response-email-input" 
                            value={currentResponseEmail} 
                            onChange={handleEmailEdit} 
                            placeholder="Type response email here"
                        />
                    </div>

                    

                    

                    {/* Site manager */}
                    {emailOptions.map((email, i)=>{
                        const selectEmail = handleSelect(email);
                        const selectedClass = selectedEmail === email ? "selected" : '';

                        return (
                            <div  key={`email-option-${i}`}  className={`location-option-wrap ${selectedClass}`}>
                                <button type="button" className="location-option-text" onClick={selectEmail}>{email}</button>
                            </div>
                        )
                    })}
                    
                </div>        

            <button className="digitizer" onClick={closeModal}></button>    
        </div>
    )
}


export default SelectResponseEmailModal;