import defaultState from "../default-state";
import { AutoTextarea } from "./auto-textarea/auto-textarea";
import InfoBlock from "./info-block";


const BlockSelect = ({formikValues})=> {
    const {
        values,
        handleChange,
        setFieldValue,
    } = formikValues;

    const clearBlockValues =()=>{
        setFieldValue('description', defaultState.description);
    }

    return (
        <InfoBlock
        title="Description"
        clearBlock={clearBlockValues}
        >
            <div className="center-flex">
                <AutoTextarea name="description" className="txta description" onChange={handleChange('description')} rows={4} value={values.description} />
            </div>
        </InfoBlock>
    )

}

export default BlockSelect;