import { useState } from 'react';
import locationData from './select-location/location-data';
import SelectLocationModal from './select-location/select-location-modal';

const bodyModalClass = ' modal-open';

const SelectLocation = ({value, onChange})=>{
    const [showLocationModal, setShowLocationModal] = useState(false);

    const showModal = ()=>{
        const body = document.getElementsByTagName('body')[0];
        body.className = `body.className ${bodyModalClass}`;
        setShowLocationModal(true);
    }
    const hideModal = ()=>{
        const body = document.getElementsByTagName('body')[0];
        body.className = body.className.replace(bodyModalClass, '').trim();
        setShowLocationModal(false);
    }

    const handleLocation = (buildingId) => (
        () => {
            onChange(buildingId);
            hideModal();
        }    
    );

    return (
        <>
            <button type="button" className='open-select-modal' onClick={showModal}>{ value === false ? "Select a location" : locationData[value].name }</button>
            {
                showLocationModal ? <SelectLocationModal closeModal={hideModal} selectedIndex={ (value || false)} handleChange={handleLocation}/> : null
            }
        </>

    )
}
export default SelectLocation;