import defaultState from "../default-state";
import { generateFilename } from "./generate-title";
import InfoBlock from "./info-block"
import InputGroup from "./input-group";


export const BlockFilename = ({formikValues, showBlockDefault}) =>{
    const {
        values,
        handleChange,
        setFieldValue,
        // and other goodies
    } = formikValues;



    const filenamePlaceholder = generateFilename(values.general.locationId, values.general.title, values.general.date);
        
    

    return (
        <InfoBlock 
        title="File"
        clearBlock={false}
        showBlockDefault={showBlockDefault}
        >
            <InputGroup
            title="Date" 
            onChange={handleChange('general.date')} 
            value={values.general.date}
            
            />

            <InputGroup
            title="PDF filename" 
            onChange={handleChange('general.filename')} 
            value={values.general.filename}
            placeholder={filenamePlaceholder}
            />
        </InfoBlock>
        
    )
}