import { useRef, useLayoutEffect, useState} from 'react';

export const AutoTextarea = ({value, ...props})=>{
    const [textareaMinHeight, setMinHeight]= useState(0);
    const textareaRef = useRef(false);

    useLayoutEffect(()=>{
        if(textareaRef) {
            const newMinHeight = !value ? 0 :
                (textareaRef.current.scrollHeight);
            if(textareaMinHeight !== newMinHeight)
                setMinHeight(newMinHeight);
        } 
    }, [value])

    const styles = {
        minHeight: textareaMinHeight
    }

    return (
        <textarea rows={1} {...props} value={value} ref={textareaRef} style={styles} > </textarea>
    )
}