const now = new Date;
const monthString = now.getMonth().toString().length === 1 ? `0${(now.getMonth() + 1)}` : (now.getMonth() + 1);
const dateString = now.getDate().toString().length === 1 ? `0${now.getDate()}` : now.getDate();


const defaultState = {
    general: {
        filename: "",
        title: "",
        date: `${now.getFullYear()}-${monthString}-${dateString}`,
        locationId: false,
        responseEmail: "",
        responseDeadline: "",
    },
    description: "",
    priceMustInclude: [],
    conditions: [
        'COI required before work',
        'Final cost must include basic materials and labor',
        'Bill as services are completed',
    ],
    custom: {
        title: false,
        items: []
    },
    file: {
        date: ``,
        filename: "",
    }
}


export default defaultState;
