const jSanchez = 'JSanchez@MonarchManage.com';

const unsortedLocations = [

    {
        name: "Belmont Knoll Senior Apartments", 
        address: "1 Center Ave",
        city: "Mt Pocono",
        state: "PA",
        zip: "18344",
        stringId: "bel",
    }, 
    {
        name: "Broadway Estates", 
        address: "524 Broadway Estates",
        city: "Turbotville",
        state: "PA",
        zip: "17772",
        
    }, 
    {
        name: "Finch Towers", 
        address: "424 Wyoming Ave",
        city: "Scranton",
        state: "PA",
        zip: "18503",
        
        locationEmails: [jSanchez]
    }, 
    {
        name: "Knob Crest Senior Apartments", 
        address: "3 Knob road",
        city: "Mt Pocono",
        state: "PA",
        zip: "18344",
        stringId: "Knob Crest",
        
    }, 
    {
        name: "Laurel Place", 
        address: "264 South Street",
        city: "Waymart",
        state: "PA",
        zip: "18472",
        
        locationEmails: [jSanchez]
    }, 
    {
        name: "Stourbridge Square Associates", 
        address: "316 11th street",
        city: "Honesdale",
        state: "PA",
        zip: "18431",
        stringId: "Stourbridge Square",
        locationEmails: [jSanchez]
    }, 
    {
        name: "Deerfield Estates", 
        address: "Deerfield Street",
        city: "Beaver Springs",
        state: "PA",
        zip: "17812",
        
    }, 
    {
        name: "Delaware Run", 
        address: "603 Avenue H",
        city: "Matamoras",
        state: "PA",
        zip: "18336",
        
    }, 
    {
        name: "Frackville Flats", 
        address: "14 North Nice Street",
        city: "Frackville",
        state: "PA",
        zip: "17931",
        
    }, 
    {
        name: "Gennaro Gardens", 
        address: "522 North Church Street",
        city: "Hazleton",
        state: "PA",
        zip: "18201",
        
    }, 
    {
        name: "Hamlin Heights", 
        address: "549 Easton Turnpike",
        city: "Lake Ariel",
        state: "PA",
        zip: "18436",
        
    }, 
    {
        name: "Heritage Heights", 
        address: "701 Heritage Hills",
        city: "Pottsville",
        state: "PA",
        zip: "17901",
        
    }, 
    {
        name: "Heritage Hills", 
        address: "701 Heritage Hills",
        city: "Pottsville",
        state: "PA",
        zip: "17901",
        
    }, 
    {
        name: "Hite House", 
        address: "121 West Main Street",
        city: "Stoystown",
        state: "PA",
        zip: "15563",
        
    }, 
    {
        name: "Lincoln Heights", 
        address: "5 Defoe Street",
        city: "Pittston",
        state: "PA",
        zip: "18640",
        
    }, 
    {
        name: "McVeytown Apartments", 
        address: "277 West Holiday Street",
        city: "McVeytown",
        state: "PA",
        zip: "17051",
        
    }, 
    {
        name: "Meadows Edge", 
        address: "620 Girard Street",
        city: "Mill Hall",
        state: "PA",
        zip: "17751",
        
    }, 
    {
        name: "Miners Lofts", 
        address: "2 Lewis Street",
        city: "Minersville",
        state: "PA",
        zip: "17954",
        
    }, 
    {
        name: "Mountain View Estates", 
        address: "152 Horvath Drive",
        city: "Elsyburg",
        state: "PA",
        zip: "17824",
        
    }, 
    {
        name: "Oak Dale Estates", 
        address: "41 Oak Dale Estates",
        city: "Orwigsburg",
        state: "PA",
        zip: "17961",
        
    }, 
    {
        name: "Oak Ridge Estates", 
        address: "33 Oak Ridge Lane",
        city: "Mountain Top",
        state: "PA",
        zip: "18707",
        
    }, 
    {
        name: "Park Places", 
        address: "69 Park Place",
        city: "Box 61, Muncy",
        state: "PA",
        zip: "17756",
        
    }, 
    {
        name: "Paxton Place", 
        address: "1100 South 20th Street",
        city: "Harrisburg",
        state: "PA",
        zip: "17104",
        
    }, 
    {
        name: "The Point at Sporting Hill", 
        address: "North Sporting Hill Road",
        city: "Mechancisburg",
        state: "PA",
        zip: "17050",
        
    }, 
    {
        name: "Riverton Woods", 
        address: "North Twelfth Street",
        city: "Lemoyne",
        state: "PA",
        zip: "17043",
        
    }, 
    {
        name: "Rosewood Apartments", 
        address: "100 Birch Place",
        city: "Berwick",
        state: "PA",
        zip: "18603",
        
    }, 
    {
        name: "Rothrock School Apartments", 
        address: "280 West Holiday Street",
        city: "McVeytown",
        state: "PA",
        zip: "17051",
        
    }, 
    {
        name: "Roymar Hall", 
        address: "1128 E. Lincoln Highway",
        city: "Coatesville",
        state: "PA",
        zip: "19320",
        
    }, 
    {
        name: "Shepherd's Crossing", 
        address: "251 Lambs Gap Road",
        city: "Mechanicsburg",
        state: "PA",
        zip: "17050",
        
    }, 
    {
        name: "Sparrow's Way", 
        address: "109 Sparrows Way",
        city: "York",
        state: "PA",
        zip: "17408",
        
    }, 
    {
        name: "Spring Brae", 
        address: "101 Terra Sylvan Lane",
        city: "Bellefonte",
        state: "PA",
        zip: "16823",
        
    }, 
    {
        name: "Steeple View", 
        address: "57 Carroll Street",
        city: "Pittston",
        state: "PA",
        zip: "18640",
        
    }, 
    {
        name: "Sunflower Fields", 
        address: "2101 Sunflower Drive",
        city: "Harrisburg",
        state: "PA",
        zip: "17110",
        
    }, 
    {
        name: "Terra Sylvan", 
        address: "101 Terra Sylvan Lane",
        city: "Bellefonte",
        state: "PA",
        zip: "16823",
        
    }, 
    {
        name: "The Flats at Factory Square", 
        address: "270 B Street",
        city: "Carlisle",
        state: "PA",
        zip: "17013",
        
    }, 
    {
        name: "The Mill at Easton", 
        address: "620 Coal Street",
        city: "Easton",
        state: "PA",
        zip: "18042",
        
    }, 
    {
        name: "Townhomes at Factory Square", 
        address: "238 C Street",
        city: "Carlisle",
        state: "PA",
        zip: "17013",
        
    }, 
    {
        name: "Whispering Pines", 
        address: "29 Whispering Pines Blvd",
        city: "Pine Grove",
        state: "PA",
        zip: "17963",
        
    }, 
    {
        name: "Woodbryn", 
        address: "33 Oak Ridge Lane",
        city: "Mountain Top",
        state: "PA",
        zip: "18707",
        
    }
];




function sortByName(b1, b2) {
    if(b1.name > b2.name) return 1;
    else if(b1.name < b2.name) return -1;
    else return 0;
}

const locationData = [...unsortedLocations].sort(sortByName);

export default locationData;



