import { generateFilename } from "../generate-title";
import locationData from "../select-location/location-data";

const cleanValues = (values) => {
  const cleanedValues = {};
  for (const valueKey of Object.keys(values)) {
    // Trim string whitespace
    const cleanedValue = 
      typeof values[valueKey] === 'string' ? 
      values[valueKey].trim() : 
      values[valueKey] ;
    cleanedValues[valueKey] = cleanedValue;
  }
  
  // Filename generation
  if(!cleanedValues.general.filename) {
    cleanedValues.general.filename = generateFilename(cleanedValues.general.locationId, cleanedValues.general.title, cleanedValues.general.date);
  }

  // locationId to location values
  const locationId = values.general.locationId;
  if(locationId || locationId === 0) {
    const {name, address, city, state, zip} = locationData[locationId];
    cleanedValues.general = {
      ...cleanedValues.general,
      name,
      address,
      city,
      state, 
      zip
    };
  }

  // delete cleanedValues.general.locationId;
  return cleanedValues;
};


const formikOnsubmit = async (values, { setSubmitting }) => {
      const cleanedValues = cleanValues(values);
    
      // const requestURL = 'http://monarch.maxbernard.design/create-rfp-pdf.php';
      // const requestURL = 'http://monarch-rfp.local/create-rfp-pdf.php';
      const requestURL = '/create-rfp-pdf.php';

      const response = await fetch(
        // requestURL
        requestURL, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          // mode: "no-cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cleanedValues)
        }
      );

      // const pdfText = await response.text();
      // console.log(pdfText);
      //   return true;
      const pdfFilename = (cleanedValues.general.filename || "Request-for-proposal.pdf");
      const pdfBlob = await response.blob();
      const pdfFile = new File([pdfBlob], pdfFilename,  { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfFile);

      downloadSimpler(pdfUrl, pdfFilename);

      // let file = new Blob([response.data], { type: 'application/pdf' });
      // let fileURL = URL.createObjectURL(file);
      
      // window.open(pdfUrl, '_blank');
}

function downloadSimpler(pdfFile, pdfFilename) {
  let fileLink = document.createElement('a');
  fileLink.href = pdfFile;
  fileLink.download = pdfFilename;
  fileLink.click();
}

function downloadPDF(blobData) {
    // create `objectURL` of `this.response` : `.pdf` as `Blob`
    var file = window.URL.createObjectURL(blobData);
    var a = document.createElement("a");
    a.href = file;
    a.download = "test PDF";
    document.body.appendChild(a);
    a.click();
    // remove `a` following `Save As` dialog, 
    // `window` regains `focus`
    window.onfocus = function () {                     
      document.body.removeChild(a)
    }
  
}
export default formikOnsubmit