
import { Formik } from 'formik';
import defaultState from '../default-state';
import formikValidate from './formik/formik-validate';
import formikOnsubmit from './formik/formik-on-submit';
import BlockGeneral from './general-block/general-block';
import BlockSelect from './block-description';
import ListInfoBlock from './list-info-block';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { BlockFilename } from './block-filename';

import locationData from './select-location/location-data';




const FormikWrap = ({children})=>{
    return (
    <Formik
    initialValues={defaultState}
    validate={formikValidate}
    onSubmit={formikOnsubmit}
    >
        {(formikValues) => {
            return (
                <form className="formik-form" onSubmit={formikValues.handleSubmit}>

                    {/* General */}
                    <BlockGeneral 
                    formikValues={formikValues}
                    />

                    {/* DESCRIPTION  */}
                    <BlockSelect formikValues={formikValues} /> 

                    {/* Price must include */}
                    <ListInfoBlock 
                    title="PRICE MUST INCLUDE"
                    formikSelector='priceMustInclude'
                    formikValues={formikValues} 
                    itemLimit={10}
                    />

                    {/* Custom */}

                    {/* CONDITIONS  */}
                    <ListInfoBlock 
                    title="CONDITIONS"
                    formikSelector='conditions'
                    formikValues={formikValues} 
                    itemLimit={6}
                    showBlockDefault={false} 
                    />

                    <div className='submit-button-wrap'>
                        <button className='submit-form' type='submit'>                        
                            <span>CREATE</span>
                            <FontAwesomeIcon icon={faArrowRight} size='sm' />
                        </button>
                    </div>

                    {/* Filename */}
                    <BlockFilename formikValues={formikValues} showBlockDefault={true} />
                </form>
            )}
        }
    </Formik>
    )
}


export default FormikWrap;

