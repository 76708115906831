import { useState, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faChevronUp } from '@fortawesome/free-solid-svg-icons';


const InfoBlock = ({title, children, clearBlock, showBlockDefault = true})=>{
    const [showBlock, setShowBlock] = useState(showBlockDefault);
    const [blockHeight, setBlockHeight] = useState(false);
    const blockRef = useRef(false);

    const toggleShowBlock = ()=>{
        setShowBlock(( showBlock ? false : true ));
    }

    useLayoutEffect(()=>{
        if(blockRef) {
            const rectHeight = (blockRef.current.scrollHeight + 50);
            if(rectHeight !== blockHeight) {
                setBlockHeight(rectHeight);
            }   
        }
    });

    const blockStyle = !blockHeight ? {} : {
        maxHeight: blockHeight
    };
    return (
        <section  className={`info-block ${showBlock ? '' : 'hide'}`}  >
            <div className="info-block-header">
                {clearBlock ? 
                <button type="button" className="clear-section" onClick={clearBlock}>
                    <FontAwesomeIcon icon={faRefresh} size='lg' />
                </button>
                : 
                <div className="clear-section"></div>
                }
                <h3 className="info-block-title">{title}</h3>
                <button type="button" className="block-toggle" onClick={toggleShowBlock}>
                    <FontAwesomeIcon className="toggle-icon" icon={ faChevronUp } size='lg' />
                </button>
            </div>
            <div style={blockStyle}  ref={blockRef} className={`info-block-content `}>
                <div className='info-block-inner-content'>

                    {children}
                </div>
            </div>
        </section>
        
    );
}

export default InfoBlock;