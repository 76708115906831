import defaultState from "../default-state";
import InfoBlock from "./info-block";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { AutoTextarea } from "./auto-textarea/auto-textarea";


const ListInfoBlock = ({formikValues, formikSelector, title, itemLimit = 5, showBlockDefault}) =>{
    const {
        values,
        handleChange,
        setFieldValue,
    } = formikValues;

    const clearBlockValues =()=>{
        setFieldValue(formikSelector, defaultState[formikSelector]);
    }

    const deleteItem = (itemIndex) => (
        ()=>{
            const newList = values[formikSelector].filter((item, i)=>(i !== itemIndex));
            setFieldValue(formikSelector, newList);
        }
    )

    const addItem = ()=>{
        if(values[formikSelector].length < itemLimit)  {
            setFieldValue(formikSelector, [...values[formikSelector], '']);
        }
        
    }

    return (
        <InfoBlock
        title={title}
        clearBlock={clearBlockValues}
        showBlockDefault={showBlockDefault}
        >
            <ul className="item-list">
                {values[formikSelector].map((itemText, i)=>(
                    <li key={`${formikSelector}-${i}`} className="item-wrap">
                        <button type='button' className="delete-item" onClick={deleteItem(i)}>
                            <FontAwesomeIcon icon={faTrash} size='sm' color="#E14040"/>
                        </button>
                        <AutoTextarea name={`${formikSelector}-txtarea-${i}`}  className="txta item-textarea" onChange={handleChange(`${formikSelector}.${i}`)} value={itemText}/>
                        {/* <textarea className="txta item-textarea" onChange={handleChange(`${formikSelector}.${i}`)} value={itemText}></textarea> */}
                    </li>
                ))}

                {(values[formikSelector].length < itemLimit) ?
                    <button type="button" onClick={addItem} className="item-counter">
                        + ADD ({values[formikSelector].length}/{itemLimit})
                    </button>
                : <div className="item-counter full">({values[formikSelector].length}/{itemLimit})</div>}
                
            </ul>
        </InfoBlock>
        
    )
}

export default ListInfoBlock;