import InfoBlock from "../info-block";
import InputGroup from "../input-group";
import defaultState from "../../default-state";
import SelectLocation from "../select-location";
import { SelectResponseEmail } from "../select-response-email/select-response-email";

/*
{
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    // and other goodies
}
*/

const BlockGeneral = ({formikValues})=> {
    const {
        values,
        handleChange,
        setFieldValue,
        // and other goodies
    } = formikValues;

    const clearGeneralValues =()=>(
        setFieldValue('general', defaultState.general)
    )

    const handleLocationChange = (value) => {
        setFieldValue('general.locationId', value);
    }

    const handleResponseEmailChange = (value) => {
        setFieldValue('general.responseEmail', value);
    }

    return (
        <InfoBlock 
        title="General"
        clearBlock={clearGeneralValues}
        >
            <div className="center-flex input-group">
                <p>Location</p>
                <SelectLocation
                onChange={handleLocationChange}
                value={values.general.locationId}
                />
            </div>

            {/* <InputGroup
            title="PDF file name" 
            onChange={handleChange('general.filename')} 
            value={values.general.filename}
            /> */}

            <InputGroup
            title="Title" 
            onChange={handleChange('general.title')} 
            value={values.general.title}
            />

            {/* <InputGroup
            title="Date" 
            onChange={handleChange('general.date')} 
            value={values.general.date}
            /> */}

            <div className="center-flex input-group">
                <p>Response email</p>
                <SelectResponseEmail 
                onChange={handleResponseEmailChange}
                value={values.general.responseEmail}
                selectedLocationIndex={values.general.locationId}
                selectedEmail={values.general.responseEmail}
                />
            </div>
            {/* <InputGroup
            title="responseEmail" 
            onChange={handleChange('general.responseEmail')} 
            value={values.general.responseEmail}
            /> */}

            <InputGroup
            title="Response deadline" 
            onChange={handleChange('general.responseDeadline')} 
            value={values.general.responseDeadline}
            />

            {/* locationId: "", */}

        </InfoBlock>
    )
}

export default BlockGeneral;